import React from "react";
import {useLocation} from '@reach/router';

const LinkMenu = ({to, children}) => {
  const {pathname} = useLocation();
  
  return <a href={to}
      className={pathname.includes(to) ? 'font-bold' : undefined}
    >{children}</a>
}

const MainMenu = () => {
  return <nav className="mx-35 main-nav mb-5">
    <LinkMenu to="/about-us">About us</LinkMenu>
    <LinkMenu to="/how-it-works">How it works</LinkMenu>
    <LinkMenu to="/our-services">Our services</LinkMenu>
  </nav>
  
}

export default MainMenu;