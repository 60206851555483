/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { IntlProvider } from 'react-intl';
import { Helmet } from 'react-helmet';

import config from '../config';
import Header from "./header"

const Layout = ({ children }) => {
  
  return (
    <IntlProvider locale={config.locale || 'en'} messages={config.translations}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=no"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,700|PT+Sans:400,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Header />
      <main>{children}</main>
      <footer>
        <div className="container w-full mx-auto xl:px-105 my-20 lg:my-25 text-center lg:text-left text-grey">
          <p>Copyright © 2021 Yource B.V.</p>
          <p><a className="text-sm" href="/privacy-statement">Privacy statement</a></p>
        </div>
      </footer>
    </IntlProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
