import React from "react"
import clsx from 'clsx';

import yourceLogo from '../images/yource-logo.svg';

import config from '../config';
import MainMenu from "./MainMenu";

const Header = () => {

  return (
    <header
      className={clsx(
        'flex items-center pt-30',
        {
        }
      )}
    >
      <div className="container w-full mx-auto xl:px-105 mdd:max-w-none flex
      items-center
      ">
          <a
            href={'//' + config.portalUrl}
            className="no-underline w-auto float-left block"
          >
            <img
              src={yourceLogo}
              alt="Yource Logo"
              className="inline-block"
            />
          </a>
        <MainMenu/>
      </div>
    </header>
  );
};

Header.propTypes = {
}

Header.defaultProps = {
}

export default Header
